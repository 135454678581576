import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useTranslation } from "react-i18next";
import { useGetallUserQuery } from "../../redux/userApi";

const Sidebar = () => {
  const { t } = useTranslation();
  const { data } = useGetallUserQuery();
  const { logout } = useAuth();
  const page = window.location.pathname;
  const navigate = useNavigate();
  const [isUserIb, setIsUserIb] = useState(false);

  const logoutPage = () => {
    toast.success(t("Logout successfully"));
    localStorage.clear();
    navigate("/login");
    logout();
  };

  useEffect(() => {
    if (data) {
      if (data?.role === "Informatic Broker (IB)") {
        setIsUserIb(true);
      } else {
        setIsUserIb(false);
      }
    }
  }, [data]);

  return (
    <div className="col-xl-3 col-lg-4">
      <div className="p-3  p-md-4 bg2 br20 manu_box" id="hamburger-menue">
        <ul className="dashboard_manu">
          <li>
            <Link
              to="/dashboard"
              className={page === "/dashboard" ? "active" : ""}
            >
              <i className="bi bi-grid"></i> {t("Dashboard")}
            </Link>
          </li>
          {/* <li>
            <Link
              to={"/withdrawal"}
              className={page === "/withdrawal" ? "active" : ""}
            >
              <i className="bi bi-database-down"></i>Withdraw
            </Link>
          </li> */}
          {/* <li>
            <Link
              to="/internal-transfer"
              className={page === "/internal-transfer" ? "active" : ""}
            >
              <i className="bi bi-send"></i>Internal Transfer
            </Link>
          </li> */}
          <li>
            <Link
              to="/two-factory"
              className={page === "/two-factory" ? "active" : ""}
            >
              <i className="bi bi-shield-check"></i>
              {t("Google 2FA")}
            </Link>
          </li>
          <li>
            <Link
              to="/change-password"
              className={page === "/change-password" ? "active" : ""}
            >
              <i className="bi bi-key"></i>
              {t("Change Password")}
            </Link>
          </li>
          <li>
            <Link
              to="/trade-history"
              className={page === "/trade-history" ? "active" : ""}
            >
              <i className="bi bi-clock-history"></i>
              {t("Trade History")}
            </Link>
          </li>
          <li>
            <Link
              to="/transaction-history"
              className={page === "/transaction-history" ? "active" : ""}
            >
              <i className="bi bi-card-list"></i>
              {t("Transaction History")}
            </Link>
          </li>
          <li>
            <Link to="/account" className={page === "/account" ? "active" : ""}>
              <i className="bi bi-currency-bitcoin"></i>
              {t("Wallet")}
            </Link>
          </li>
          <li>
            <Link
              to="/verify-identity"
              className={page === "/verify-identity" ? "active" : ""}
            >
              <i className="bi bi-person-check"></i>
              {t("Verify Identity")}
            </Link>
          </li>
          {isUserIb === true && (
            <li>
              <Link
                to="/referral"
                className={page === "/referral" ? "active" : ""}
              >
                <i className="bi bi-people"></i>
                {t("Referral")}
              </Link>
            </li>
          )}
          <li>
            <Link
              to="/activity"
              className={page === "/activity" ? "active" : ""}
            >
              <i className="bi bi-clock-history"></i>
              {t("Activity")}
            </Link>
          </li>
          <li>
            <Link to="/support" className={page === "/support" ? "active" : ""}>
              <i className="bi bi-headset"></i>
              {t("Support")}
            </Link>
          </li>
          <li>
            <Link to="/support" className="dp_none" onClick={logoutPage}>
              <i className="bi bi-box-arrow-right"></i>
              {t("Logout")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
