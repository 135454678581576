import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./AuthContext";
import Login from "./components/auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import { Toaster } from "react-hot-toast";
import Register from "./components/auth/Register";
import OnBoarding from "./components/pages/OnBoarding";
import Dashboard from "./components/widgets/Dashboard";
import CryptoExchange from "./components/pages/CryptoExchange";
import Forgot from "./components/auth/Forgot";
import Kyc from "./components/pages/Kyc";
import Support from "./components/pages/Support";
import Chatting from "./components/pages/Chatting";
import NotFound from "./components/widgets/404";
import ForexExchange from "./components/pages/ForexExchange";
import CryptoAssets from "./components/pages/CryptoAssets";
import GoogleAuthentication from "./components/pages/GoogleAuthentication";
import CryptoTradeHistory from "./components/pages/CryptoTradeHistory";
import CryptoTransactionHistory from "./components/pages/CryptoTransactionHistory";
import EquityExchange from "./components/pages/EquityExchange";
import ForexMobile from "./components/partials/ForexMobile";
import UserActivity from "./components/pages/UserActivity";
import ChangePassword from "./components/pages/ChangePassword";
import ForexExchangeEquity from "./components/pages/ForexExchangeEquity";
import TradingViewCryptoMobile from "./components/partials/TradingViewCryptoMobile";
import ForgotUsername from "./components/auth/ForgotUsername";
import Referral from "./components/pages/Referral";
import { useDispatch } from "react-redux";
import { setUserSettings } from "./redux/userSlice";
import { useGetallUserQuery } from "./redux/userApi";

const App = () => {
  const jwtToken = localStorage.getItem("jwtToken");
  const { login } = useAuth();
  const dispatch = useDispatch();
  const shouldFetchUser = !!jwtToken; // Only fetch if the token exists
  const { data, isLoading } = useGetallUserQuery(undefined, {
    skip: !shouldFetchUser, // Skip the query if the token doesn't exist
  });

  useEffect(() => {
    if (jwtToken) {
      login();
    }
    if (data) {
      console.log(data, "data855");
      dispatch(setUserSettings(data)); // Dispatch action to set user settings in Redux
    }
  }, [jwtToken, login, data, dispatch]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/forgot-username" element={<ForgotUsername />} />
          <Route
            path="/onboarding"
            element={<ProtectedRoute component=<OnBoarding /> />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component=<Dashboard /> />}
          />
          <Route
            path="/crypto/:firstCoin/:secondCoin"
            element={<ProtectedRoute component=<CryptoExchange /> />}
          />
          <Route
            path="/mobile-crypto/:firstCoin/:secondCoin"
            element={<TradingViewCryptoMobile />}
          />
          <Route path="/mobile-forex/:symbol" element={<ForexMobile />} />
          <Route
            path="/stocks/:symbol"
            element={<ProtectedRoute component=<EquityExchange /> />}
          />
          <Route
            path="/forex/:symbol"
            element={<ProtectedRoute component=<ForexExchange /> />}
          />
          <Route
            path="/forex-equity/:symbol"
            element={<ProtectedRoute component=<ForexExchangeEquity /> />}
          />

          <Route
            path="/verify-identity"
            element={<ProtectedRoute component=<Kyc /> />}
          />
          <Route
            path="/support"
            element={<ProtectedRoute component=<Support /> />}
          />
          <Route
            path="/change-password"
            element={<ProtectedRoute component=<ChangePassword /> />}
          />
          <Route
            path="/account"
            element={<ProtectedRoute component=<CryptoAssets /> />}
          />
          <Route
            path="/two-factory"
            element={<ProtectedRoute component=<GoogleAuthentication /> />}
          />
          <Route
            path="/referral"
            element={<ProtectedRoute component=<Referral /> />}
          />
          <Route
            path="/trade-history"
            element={<ProtectedRoute component=<CryptoTradeHistory /> />}
          />
          <Route
            path="/transaction-history"
            element={<ProtectedRoute component=<CryptoTransactionHistory /> />}
          />
          <Route
            path="/activity"
            element={<ProtectedRoute component=<UserActivity /> />}
          />
          <Route
            path="/Chatting/:ticketId/:status"
            element={<ProtectedRoute component=<Chatting /> />}
          />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Toaster position="top-right" />
    </div>
  );
};

export default App;
