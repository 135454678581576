import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import toast from "react-hot-toast";
import { useGetCryptoPortfolioQuery } from "../../redux/userApi";
import { useDropdown } from "./useDropdown";
import { useGetUserForexPortfolioMutation } from "../../redux/forexApi";

import { myApi } from "../../redux/api";
import { useDispatch } from "react-redux";

import ChangeLang from "./ChangeLang";
import { useTranslation } from "react-i18next";
import { formatDecimal } from "../../util/decimalFormatter";

const Navbar = () => {
  const token = localStorage.getItem("jwtToken");
  const { t } = useTranslation();

  // const scriptAdded = useRef(false);

  const dispatch = useDispatch();
  const themeDropdown = useDropdown();
  const menuDropdown = useDropdown();
  const [userPortfolioForex] = useGetUserForexPortfolioMutation();

  const { logout } = useAuth();
  const navigate = useNavigate();
  const [selectedTheam, setSelectedTheam] = useState("");
  const [floatingProfits, setFloatingProfits] = useState(0.0);

  const [freeBalance, setFreeBalance] = useState(0.0);
  const [usedBalance, setUsedBalance] = useState(0.0);
  const [totalBalance, setTotalBalance] = useState(0.0);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      getEquityPortfolio();
    }
  }, []);

  const getEquityPortfolio = async () => {
    const data = {
      symbolType: "equity",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp.status === true) {
      setFreeBalance(resp.data.freeBalance ? resp.data.freeBalance : "0.0");
      setUsedBalance(resp.data.usedMargin ? resp.data.usedMargin : "0.0");
      setTotalBalance(resp.data.balance ? resp.data.balance : "0.0");
    }
  };

  const logoutPage = () => {
    dispatch(myApi.util.resetApiState());
    toast.success(t("Logout successfully"));
    localStorage.clear();
    navigate("/login");
    logout();
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.className = theme;
    }
  }, []);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.className = theme;
      setSelectedTheam(theme);
    }
  }, []);

  const setTheme = (e, themeName) => {
    e.preventDefault();
    if (themeName === "defaultTheme") {
      document.body.className = "";
    } else {
      document.body.className = themeName;
    }
    setSelectedTheam(themeName);
    localStorage.setItem("theme", themeName);
  };

  return (
    <div className="header_main bg2">
      <div className="container">
        <div className="manu d-flex align-items-center">
          <Link to="/">
            <img
              className="head_logo logo_lite"
              src="/img/logo.png"
              alt="Head Logo"
            />
            <img
              src="/img/logo.png"
              alt="Head Logo"
              className="head_logo logo_dark"
            />
          </Link>
          <div className="head_menu_div ml-auto  ">
            <ul
              id="menu-header-menu"
              className=" ml-auto align-items-center d-flex"
            ></ul>
          </div>
          {token && (
            <div className="row current_unds_item">
              <div className="mr-3">
                <p className=" mb-0">
                  {t("Total ")}: $
                  {isNaN(totalBalance)
                    ? "0.00"
                    : formatDecimal(totalBalance, 2)}
                </p>
              </div>
              <div className="mr-3">
                <p className="mb-0">
                  {t("Used")}: $
                  {isNaN(usedBalance) ? "0.00" : formatDecimal(usedBalance, 2)}
                </p>
              </div>
              <div className="mr-5">
                <p className="mb-0">
                  {t("Free")}: $
                  {isNaN(freeBalance) ? "0.00" : formatDecimal(freeBalance, 2)}
                </p>
              </div>
            </div>
          )}

          <ChangeLang />

          {!token && (
            <>
              <Link className="btn ml-3" to={"/login"}>
                {t("Login")}
              </Link>

              <Link className="btn ml-3" to={"/register"}>
                {t("Signup")}
              </Link>
            </>
          )}

          <div className="dropdown user_dropdown mr-3" style={{ left: "20px" }}>
            <span
              type="button"
              className="dropdown-toggle color-toggle mr-3"
              data-toggle="dropdown"
              onClick={() => themeDropdown.setIsOpen(!themeDropdown.isOpen)}
            >
              {selectedTheam === "lightTheme" ? (
                <i className="bi bi-brightness-high"></i>
              ) : selectedTheam === "defaultTheme" ? (
                <i className="bi bi-moon-stars"></i>
              ) : (
                <i className="bi bi-moon"></i>
              )}{" "}
            </span>
            <div
              ref={themeDropdown.ref}
              className={
                themeDropdown.isOpen
                  ? "dropdown-menu2 active"
                  : "dropdown-menu2"
              }
            >
              <a
                className="a_flex"
                href="#!"
                onClick={(e) => setTheme(e, "lightTheme")}
              >
                <i className="bi bi-brightness-high mr-1"></i> {t("Light")}
              </a>
              <a
                className="a_flex"
                href="#!"
                onClick={(e) => setTheme(e, "defaultTheme")}
              >
                <i className="bi bi-moon-stars mr-1"></i> {t("Dim")}
              </a>
              <a
                className="a_flex"
                href="#!"
                onClick={(e) => setTheme(e, "darkTheme")}
              >
                <i className="bi bi-moon mr-1"></i> {t("Dark")}
              </a>
            </div>
          </div>

          {token && (
            <div className="dropdown user_dropdown ">
              <span
                type="button"
                className=" dropdown-toggle"
                data-toggle="dropdown"
                onClick={() => menuDropdown.setIsOpen(!menuDropdown.isOpen)}
              >
                <i className="bi bi-person"></i>{" "}
              </span>
              <div
                ref={menuDropdown.ref}
                className={
                  menuDropdown.isOpen
                    ? "dropdown-menu2 active"
                    : "dropdown-menu2"
                }
              >
                <Link className="a_flex" to="/dashboard">
                  <i className="bi bi-grid mr-2"></i>
                  {t("Dashboard")}
                </Link>
                <Link className="a_flex" to={""} onClick={logoutPage}>
                  <i className="bi bi-box-arrow-right mr-2"></i>
                  {t("Logout")}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
